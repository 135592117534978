import React, {useEffect, useLayoutEffect, useState} from "react";
import {useModal} from "../context";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import CustomScroll from 'react-custom-scroll';
import Footer from "../components/Footer";
import Header from "../components/Header";
import useEffectOnce from "../hooks/useEffectOnce";
import Result from "../components/modals/Result";
import {goToAnchor} from "../helpers/go-to-anchor";
import ReactPlayer from "react-player";

function Home() {
    const [preCards, setPreCards] = useState([0, 0, 0]);
    const [winnersEmpty, setWinnersEmpty] = useState('Победители не определены');
    const [winners, setWinners] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [winnersSearch, setWinnersSearch] = useState(false);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [enablePageLoading, setEnablePageLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const { show, hide} = useModal();

    const [winPaging, setWinPaging] = useState({
        total: 1,
        current_page: 1
    });

    useEffect(()=>{
        //show(<Success close={hide}/>, "modal--form modal--reg");
        setTimeout(()=>{
            setLoaded(true);
        }, 1000);
    }, []);

    useEffectOnce(()=>{
        getWinners();
    });

    const resetPrediction = () => {
        setPreCards([0, 0, 0]);
    }

    const schema = yup.object({
        email: yup.string().email('Некорректный email').required('Некорректный email'),
    }).required();

    const { register, watch, setError, setValue, clearErrors, reset, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const getWinners = async(page = 1) => {
        setLoading(true);
        let query = process.env.REACT_APP_API_PROXY + 'getWinners?page=' + page;
        await fetch(query, {
            method: "GET",
            headers: {
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                setWinners(winners.concat(response.data.rows));
                setWinPaging(response.data.paging);
                setLoading(false);
                setTimeout(function (){
                   setEnablePageLoading(true);
                });
                /*if(page !== 1){
                    goToAnchor('winners');
                }*/
            }
        });
    }

    const onSearchSubmit = async (data) => {
        setLoading(true);
        setSearchLoading(true);

        await fetch(process.env.REACT_APP_API_PROXY + 'getWinners?email=' + data.email, {
            method: "GET",
            headers: {
                Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
            }
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                if(response.data){
                    setWinners(response.data.rows);
                    setWinPaging({
                        total: 1,
                        current_page: 1
                    });
                }else{
                    setWinners([]);
                    setWinPaging({
                        total: 1,
                        current_page: 1
                    });
                }
                setWinnersSearch(true);
                setLoading(false);
                setWinnersEmpty('Среди победителей данный аккаунт не найден');
            }else{
                if(response.error && response.error.email){
                    setError('email', { type: 'custom', message: response.error.email[0] });
                }
            }

            setSearchLoading(false);
        });
    }

    return (
        <>
            <Header/>
                <div className="content">
                    <div id="promo" className={'sc__promo' + (loaded ? ' loaded' : '')}>
                        <div className="sc__promo-bg--1"/>
                        <div className="sc__promo-decor--1"/>
                        <div className="sc__promo-bg--3"/>
                        <div className="sc__promo-bg--2"/>
                        <div className="sc__promo-decor--2"/>
                        <div className="sc__promo-decor--3"/>
                        <div className="sc__promo-decor--4"/>
                        <div className="sc__promo-decor--5"/>
                        <div className="sc__promo-decor--6"/>
                        <div className="sc__promo-decor--7"/>

                        <div className="grass grass--1"/>
                        <div className="grass grass--2"/>
                        <div className="grass grass--3"/>
                        <div className="grass grass--4"/>
                        <div className="grass grass--5"/>

                        <div className="promo__prize">
                            <img src={require("../assets/img/main-prize.png")}/>
                        </div>
                        <div className="promo__card promo__card--1">
                            <img src={require("../assets/img/card-base.png")}/>
                        </div>
                        <div className="promo__card promo__card--3">
                            <img src={require("../assets/img/card-center.png")}/>
                        </div>
                        <div className="promo__card promo__card--2">
                            <img src={require("../assets/img/card-base.png")}/>
                        </div>


                        <div className="vg-decor-1 mb-only"/>

                        <div className="container">
                            <div className="promo__slogan">
                                <img src={require("../assets/img/slogan.png")}/>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="end__title text-center">
                                    акция <br className="mb-only"/>
                                    завершена
                                </div>
                                {/*<div onClick={()=>{
                                    goToAnchor('prediction');
                                }} className="button button--large d-flex align-items-center justify-content-center">
                                    Сделать расклад
                                </div>*/}
                            </div>
                            <div className="promo__video">

                                <img src={require("../assets/img/video-bg.jpg")} className="fadeIn animated"/>

                                {!videoPlaying  && (
                                    <>
                                        <img src={require("../assets/img/video-bg.jpg")} className="fadeIn animated"/>
                                        <div className="play__video fadeIn animated" onClick={()=>{
                                            setVideoPlaying(true);
                                        }}/>
                                    </>
                                )}
                                {videoPlaying && (
                                    <div className="promo__video-box fadeIn animated">
                                        <ReactPlayer
                                            url='https://www.youtube.com/embed/AH0g3cu2Atc?si=bbJSSkZS_DowgkpT'
                                            playing={true}
                                            playsinline={true}
                                            onEnded={()=>{
                                                setVideoPlaying(false);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="d-flex justify-content-center">
                                <a href="https://brands.5ka.ru/restoriya#catalog" target="_blank" className="button button--medium d-flex align-items-center justify-content-center">
                                    Перейти в каталог
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="steps" className="sc__steps">
                        <div className="vg-decor-1 desk-only"/>
                        <div className="vg-decor-2"/>
                        <div className="vg-decor-9 mb-only"/>
                        <div className="vg-decor-4 mb-only"/>
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title text-center">
                                    Как выиграть призы?
                                </div>
                            </div>
                            <div className="steps__wrap d-flex justify-content-between">
                                <div className="steps__it">
                                    <div className="steps__it-numb d-flex align-items-center justify-content-center">
                                        1
                                    </div>
                                    <div className="steps__it-descr text-center">
                                        Сделай <br/>
                                        расклад
                                    </div>
                                </div>
                                <div className="steps__it">
                                    <div className="steps__it-numb d-flex align-items-center justify-content-center">
                                        2
                                    </div>
                                    <div onClick={()=>{
                                        goToAnchor('prediction');
                                    }} className="button button--custom d-flex align-items-center justify-content-center">
                                        <div>
                                            Получи <br className="desk-only"/>
                                            предсказание
                                        </div>
                                    </div>
                                </div>
                                <div className="steps__it">
                                    <div className="steps__it-numb d-flex align-items-center justify-content-center">
                                        3
                                    </div>
                                    <div className="steps__it-descr text-center">
                                        Зарегистрируйся <br/>
                                        на сайте
                                    </div>
                                </div>
                                <div className="steps__it">
                                    <div className="steps__it-numb d-flex align-items-center justify-content-center">
                                        4
                                    </div>
                                    <div className="steps__it-descr text-center">
                                        Участвуй <br/>
                                        в&nbsp;розыгрыше призов
                                    </div>
                                </div>
                            </div>
                            <div className="prizes__list">
                                <div className="prizes__it">
                                    <div className="prizes__it-img">
                                        <img src={require("../assets/img/pr-1.jpg")}/>
                                    </div>
                                    <div className="prizes__it-bottom d-flex justify-content-center">
                                        <div className="d-flex align-items-center prizes__it-count">
                                            Еженедельно, 15 шт
                                        </div>
                                    </div>
                                </div>
                                <div className="prizes__it">
                                    <div className="prizes__it-img">
                                        <img src={require("../assets/img/pr-2.jpg")}/>
                                    </div>
                                    <div className="prizes__it-bottom d-flex justify-content-center">
                                        <div className="d-flex align-items-center prizes__it-count">
                                            Главный приз, 1 шт
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="prediction" className="sc__prediction">
                        <div className="sc__prediction-bg">
                            <svg className="desk-only" width="1440" height="1031" viewBox="0 0 1440 1031" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                <path d="M0 80.3461C0 80.3461 277.092 0 720 0C1162.91 0 1440 80.3461 1440 80.3461L1440 1031H8.58307e-05L0 80.3461Z" fill="#FEAA41"/>
                            </svg>
                            <svg className="mb-only" width="360" height="990" viewBox="0 0 360 990" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                <path d="M0 14.6561C0 14.6561 69.273 0 180 0C290.727 0 360 14.6561 360 14.6561V990H0V14.6561Z" fill="#FEAA41"/>
                            </svg>
                        </div>
                        <div className="vg-decor-3"/>
                        <div className="vg-decor-4 desk-only"/>
                        <div className="container">
                            <div className="sc__header d-flex justify-content-center">
                                <div className="sc__title d-flex align-items-center justify-content-center">
                                    сделать расклад
                                </div>
                            </div>
                            <div className="prediction__wrap">
                                <div className="prediction__subtitle text-center">
                                    Выбери по одной карте в&nbsp;каждом ряду
                                </div>
                                <div className="prediction__rows">
                                    <div className="prediction__row d-flex align-items-center justify-content-center">
                                        <div className="prediction__row-header d-flex align-items-center">
                                            <div className="prediction__row-title d-flex align-items-center">
                                                Шаг 1
                                            </div>
                                            <div className="prediction__row-title-2">
                                                С кем
                                            </div>
                                        </div>
                                        <div className="prediction__row-cards">
                                            <div onClick={()=>{
                                                setPreCards([1, preCards[1], preCards[2]])
                                            }} className={'prediction__row-card' + (preCards[0] === 1 ? ' active' : '')}>
                                                <img src={require("../assets/img/card-base.png")}/>
                                            </div>
                                            <div onClick={()=>{
                                                setPreCards([2, preCards[1], preCards[2]])
                                            }} className={'prediction__row-card' + (preCards[0] === 2 ? ' active' : '')}>
                                                <img src={require("../assets/img/card-base.png")}/>
                                            </div>
                                            <div onClick={()=>{
                                                setPreCards([3, preCards[1], preCards[2]])
                                            }} className={'prediction__row-card' + (preCards[0] === 3 ? ' active' : '')}>
                                                <img src={require("../assets/img/card-base.png")}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="prediction__row d-flex align-items-center justify-content-center">
                                        <div className="prediction__row-header d-flex align-items-center">
                                            <div className="prediction__row-title d-flex align-items-center">
                                                Шаг 2
                                            </div>
                                            <div className="prediction__row-title-2">
                                                Где
                                            </div>
                                        </div>
                                        <div className="prediction__row-cards">
                                            <div onClick={()=>{
                                                setPreCards([preCards[0], 1, preCards[2]])
                                            }} className={'prediction__row-card' + (preCards[1] === 1 ? ' active' : '')}>
                                                <img src={require("../assets/img/card-base.png")}/>
                                            </div>
                                            <div onClick={()=>{
                                                setPreCards([preCards[0], 2, preCards[2]])
                                            }} className={'prediction__row-card' + (preCards[1] === 2 ? ' active' : '')}>
                                                <img src={require("../assets/img/card-base.png")}/>
                                            </div>
                                            <div onClick={()=>{
                                                setPreCards([preCards[0], 3, preCards[2]])
                                            }} className={'prediction__row-card' + (preCards[1] === 3 ? ' active' : '')}>
                                                <img src={require("../assets/img/card-base.png")}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="prediction__row d-flex align-items-center justify-content-center">
                                        <div className="prediction__row-header d-flex align-items-center">
                                            <div className="prediction__row-title d-flex align-items-center">
                                                Шаг 3
                                            </div>
                                            <div className="prediction__row-title-2">
                                                Когда
                                            </div>
                                        </div>
                                        <div className="prediction__row-cards">
                                            <div onClick={()=>{
                                                setPreCards([preCards[0], preCards[1], 1])
                                            }} className={'prediction__row-card' + (preCards[2] === 1 ? ' active' : '')}>
                                                <img src={require("../assets/img/card-base.png")}/>
                                            </div>
                                            <div onClick={()=>{
                                                setPreCards([preCards[0], preCards[1], 2])
                                            }} className={'prediction__row-card' + (preCards[2] === 2 ? ' active' : '')}>
                                                <img src={require("../assets/img/card-base.png")}/>
                                            </div>
                                            <div onClick={()=>{
                                                setPreCards([preCards[0], preCards[1], 3])
                                            }} className={'prediction__row-card' + (preCards[2] === 3 ? ' active' : '')}>
                                                <img src={require("../assets/img/card-base.png")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button onClick={()=>{
                                        show(<Result result={preCards} resetPrediction={resetPrediction} close={hide}/>, "modal--form modal--result");
                                    }} disabled={!preCards[0] || !preCards[1] || !preCards[2]} className="button button--prediction d-flex align-items-center justify-content-center">
                                        <span>Получить предсказание на лето</span>
                                        <i className="icon__sun desk-only">
                                            <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="22.3226" cy="21.9999" r="13.129" fill="white"/>
                                                <path d="M18.0645 6.74194L22.5439 0L26.9354 6.74194H18.0645Z" fill="white"/>
                                                <path d="M30.1527 8.0744L38.0873 6.47454L36.4254 14.3471L30.1527 8.0744Z" fill="white"/>
                                                <path d="M37.7581 17.5645L44.5 22.0439L37.7581 26.4354L37.7581 17.5645Z" fill="white"/>
                                                <path d="M36.4256 29.6529L38.0255 37.5876L30.1529 35.9256L36.4256 29.6529Z" fill="white"/>
                                                <path d="M18.0645 37.2581L22.5439 44L26.9354 37.2581H18.0645Z" fill="white"/>
                                                <path d="M8.5744 29.6529L6.97454 37.5876L14.8471 35.9256L8.5744 29.6529Z" fill="white"/>
                                                <path d="M7.24194 17.5645L0.5 22.0439L7.24193 26.4354L7.24194 17.5645Z" fill="white"/>
                                                <path d="M14.8473 8.0744L6.91266 6.47454L8.57462 14.3471L14.8473 8.0744Z" fill="white"/>
                                            </svg>
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div id="reset-cards" onClick={()=>{
                            resetPrediction()
                        }} className="hidden"/>
                    </div>
                    <div id="winners" className="sc__winners">
                        <div className="winners__bg-top">
                            <svg className="desk-only" width="1440" height="1032" viewBox="0 0 1440 1032" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                <path d="M0 80.3461C0 80.3461 277.092 0 720 0C1162.91 0 1440 80.3461 1440 80.3461L1440 1032H8.58307e-05L0 80.3461Z" fill="#6EA040"/>
                            </svg>
                            <svg className="mb-only" width="360" height="852" viewBox="0 0 360 852" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                <path d="M0 20C0 20 69.273 0 180 0C290.727 0 360 20 360 20V851.829H0V20Z" fill="#6EA040"/>
                            </svg>
                        </div>
                        <div className="winners__bg-bottom">
                            <svg className="desk-only" width="1440" height="1032" viewBox="0 0 1440 1032" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                <path d="M0 80.3461C0 80.3461 277.092 0 720 0C1162.91 0 1440 80.3461 1440 80.3461L1440 1032H8.58307e-05L0 80.3461Z" fill="#6EA040"/>
                            </svg>
                            <svg className="mb-only" width="360" height="852" viewBox="0 0 360 852" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                <path d="M0 20C0 20 69.273 0 180 0C290.727 0 360 20 360 20V851.829H0V20Z" fill="#6EA040"/>
                            </svg>
                        </div>
                        <div className="vg-decor-5"/>
                        <div className="container">
                            <div className="sc__header">
                                <div className="sc__title text-center">
                                    Победители
                                </div>
                            </div>
                            <div className="sc__subtitle text-center">
                                Нашли свой e-mail в&nbsp;списке? Ищите письмо от&nbsp;Рестории с&nbsp;призом! <br/>
                                Если оно не&nbsp;пришло, напишите на&nbsp;почту <a href="mailto:info@restoria-leto.ru">info@restoria-leto.ru</a>. <br/>
                                Вам ответят при любом раскладе!
                            </div>
                            {winners.length === 0 && !winnersSearch && !loading && (
                                <div className="winners__wrap winners__wrap--empty d-flex align-items-center justify-content-center">
                                    <div className="winners__wrap-ct">
                                        <div className="winners__icon">
                                            <img src={require('../assets/img/winners-icon.svg').default}/>
                                        </div>
                                        <div className="winners__text text-center">
                                            Пока нет победителей
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(winners.length > 0 || winnersSearch) && (
                                <div className="winners__wrap">
                                    <form className={'win__search' + (searchLoading ? ' form--loading' : '') + (errors.email ? ' has-error' : '')} autoComplete="off" onSubmit={handleSubmit(onSearchSubmit)}>
                                        <div className={'win__search-field' + (errors.email ? ' has-error' : '')}>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                id="email"
                                                placeholder="Поиск по e-mail"
                                                {...register("email")}
                                            />
                                        </div>
                                        {errors.email && (
                                            <p className="error__message">
                                                {errors.email?.message}
                                            </p>
                                        )}
                                        <button onClick={(e)=>{
                                            if(winnersSearch) {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                getWinners();
                                                setWinnersSearch(false);
                                                setValue('email', '');
                                            }
                                        }} type="submit" className="btn__search d-flex align-items-center justify-content-center">
                                            {!winnersSearch && (
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.5504 14.4646H15.5237L15.1669 14.1043C16.4276 12.6092 17.1944 10.6709 17.1944 8.54725C17.1944 3.82654 13.4316 0 8.78956 0C4.14753 0 0.384766 3.82654 0.384766 8.54725C0.384766 13.268 4.14753 17.0945 8.78956 17.0945C10.8778 17.0945 12.7825 16.316 14.2527 15.0353L14.6096 15.3956V16.437L20.0722 22L22.0001 20.0394L16.5504 14.4646ZM8.79011 14.4652C5.57559 14.4652 2.9714 11.8169 2.9714 8.54791C2.9714 5.28023 5.57559 2.63058 8.79011 2.63058C12.0033 2.63058 14.6088 5.28023 14.6088 8.54791C14.6088 11.8169 12.0033 14.4652 8.79011 14.4652Z" fill="#98090C"/>
                                                </svg>
                                            )}

                                            {winnersSearch && (
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.4436 16.5564L17 1M17 16.5564L1.4436 1" stroke="#98090C" strokeWidth="2"/>
                                                </svg>
                                            )}
                                        </button>
                                    </form>
                                    <div className="winners__ct">
                                        {winners.length > 0 && (
                                            <div className={'tb tb--winners' + (loading ? ' tb--loading' : '')}>
                                                <div className="tb__header desk-only">
                                                    <div className="tb__row">
                                                        <div className="tb__col">
                                                            <div className="tb__th">
                                                                Приз
                                                            </div>
                                                        </div>
                                                        <div className="tb__col">
                                                            <div className="tb__th">
                                                                Email
                                                            </div>
                                                        </div>
                                                        <div className="tb__col">
                                                            <div className="tb__th">
                                                                Дата
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'tb__body' + (winners.length < 6 ? ' tb__body--auto' : '')}>
                                                    <div className={'tb__body-scroll' + (winners.length < 6 ? ' tb__body-scroll--auto' : '')}>
                                                        <CustomScroll
                                                            onScroll={(e)=> {
                                                                const obj = e.target;
                                                                if( obj.scrollTop === (obj.scrollHeight - obj.offsetHeight) && enablePageLoading){
                                                                    if(winPaging.total > winPaging.current_page) {
                                                                        setEnablePageLoading(false);
                                                                        getWinners(winPaging.current_page + 1);
                                                                    }
                                                                }
                                                            }}
                                                            heightRelativeToParent="100%"
                                                            allowOuterScroll={true}
                                                        >
                                                            <div id="winners-scroll" className="tb__body-scroll-inner">
                                                                {winners.map((win, i) => (
                                                                    <div className="tb__row">
                                                                        <div className="tb__col" data-title="Приз">
                                                                            <div className="tb__td">
                                                                                {win.prize}
                                                                            </div>
                                                                        </div>
                                                                        <div className="tb__col" data-title="Email">
                                                                            <div className="tb__td">
                                                                                {win.email ?? '-'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="tb__col" data-title="Дата">
                                                                            <div className="tb__td">
                                                                                {win.date}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </CustomScroll>
                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                        {winners.length === 0 && (
                                            <div className={'tb__empty d-flex align-items-center justify-content-center'  + (loading ? ' tb--loading' : '')}>
                                                <div className="tb__empty-title">
                                                    {winnersEmpty}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="sc__delivery">
                        <div id="delivery" className="anchor"/>
                        <div className="delivery__img">
                            <img src={require("../assets/img/delivery-img.png")}/>
                        </div>
                        <div className="vg-decor-6"/>
                        <div className="vg-decor-7 desk-only"/>
                        <div className="vg-decor-8"/>
                        <div className="container">
                            <div className="delivery__wrap">
                                <div className="sc__header">
                                    <div className="sc__title">
                                        Доставка
                                    </div>
                                </div>
                                <div className="sc__subtitle">
                                    Все товары &laquo;Рестория&raquo; доставим <br/>
                                    от&nbsp;30&nbsp;минут
                                </div>
                                <div className="d-flex">
                                    <a href="https://5ka.ru/f/akryl" target="_blank" className="button button--medium d-flex align-items-center justify-content-center">
                                        Заказать
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
        </>
    );
}

export default Home;
