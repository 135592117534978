import { Link, useLocation  } from 'react-router-dom'
import React, {useEffect, useState} from "react";
import {useModal} from "../context";

function Header() {
    const [nav, setNav] = useState(false);
    const [fixed, setFixed] = useState(false);
    const [notShow, setNotShow] = useState(false);
    const [lkNav, setLkNav] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cookies, setCookies] = useState(false);
    const location = useLocation();
    const path = location.pathname;

    const { show, hide} = useModal();
    const [inner, setInner] = useState(false);

    useEffect(() => {
        setInner(location.pathname != '/');
        window.scroll(0, 0);
        if(location.pathname != '/'){
            document.getElementById('root').removeAttribute("style");
            document.getElementById('wrapper').removeAttribute("style");
        }

        if(location.pathname == '/'){
            //document.body.classList.add('overflow');
        }else{
            document.body.classList.remove('overflow');
        }

        setNav(false);
    }, [location.pathname]);

    useEffect(() => {
        const handleScroll = event => {
            if(window.scrollY > window.outerHeight / 3){
                setFixed(true);
            }else{
                setFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(()=>{
        if(!nav){
            document.body.classList.remove('nav-open');
        }else{
            document.body.classList.add('nav-open');
        }
    }, [nav]);

    useEffect(()=>{
        if(!localStorage.getItem('cookies')){
            setCookies(true);
        }
    }, []);

    const goToAnchor = (anchor) => {
        const el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
        setNav(false);
        setLkNav(false);
        document.body.classList.remove('overflow');
    }

    return (
       <>
           <div id="header"
                className={'header fadeIn animated' +
                    (inner ? ' header--inner' : '') +
                    (nav ? ' active' : '') +
                    (fixed ? ' header--fixed' : '') +
                    (nav ? ' header--open' : '')
                }>
               <div className="container d-flex align-items-center justify-content-between">
                   <div onClick={()=>{
                       goToAnchor('promo');
                   }} className="logo">
                       <img src={require("../assets/img/logo.svg").default}/>
                   </div>
                   <div className={'main__nav fadeIn animated' + (nav ? ' active' : '')}>
                       <div className="nav__decor--1 mb-only"/>
                       <div className="nav__decor--2 mb-only"/>
                       <div className="nav__decor--3 mb-only"/>
                       <div className="nav__decor--4 mb-only"/>
                       <div className="main__nav-bl">
                           <div className="main__menu">
                               <ul>
                                   <li>
                                       <a onClick={()=>{
                                           goToAnchor('promo');
                                       }}>Главная</a>
                                   </li>
                                   <li>
                                       <a onClick={()=>{
                                           goToAnchor('steps');
                                       }}>Как выиграть призы</a>
                                   </li>
                                   <li>
                                       <a onClick={()=>{
                                           goToAnchor('prediction');
                                       }}>Предсказание</a>
                                   </li>
                                   <li>
                                       <a onClick={()=>{
                                           goToAnchor('delivery');
                                       }}>Доставка</a>
                                   </li>
                               </ul>
                           </div>
                           <div className="main__nav-btns d-flex justify-content-center mb-only">
                               <div onClick={()=>{
                                   goToAnchor('prediction');
                               }} className="button button--large d-flex align-items-center justify-content-center">
                                   Сделать расклад
                               </div>
                           </div>
                       </div>
                   </div>

                   <div className="header__right d-flex align-items-center desk-only">
                        <div onClick={()=>{
                            goToAnchor('prediction');
                        }} className="button d-flex align-items-center justify-content-center">
                            Сделать расклад
                        </div>
                   </div>
                   <div onClick={()=>{
                       setNav(!nav);
                   }} className={'nav__bars mb-only' + (nav ? ' active' : '')}>
                       {!nav && (
                           <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2C32 3.10457 31.1046 4 30 4H2C0.895431 4 0 3.10457 0 2Z" fill="white"/>
                               <path d="M8 11C8 9.89543 8.89543 9 10 9H30C31.1046 9 32 9.89543 32 11C32 12.1046 31.1046 13 30 13H10C8.89543 13 8 12.1046 8 11Z" fill="white"/>
                               <path d="M15.1094 20C15.1094 18.8954 16.0048 18 17.1094 18H29.9983C31.1028 18 31.9983 18.8954 31.9983 20C31.9983 21.1046 31.1028 22 29.9983 22H17.1094C16.0048 22 15.1094 21.1046 15.1094 20Z" fill="white"/>
                           </svg>
                       )}

                       {nav && (
                           <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M1.68619 24.3141C0.905138 23.533 0.905138 22.2667 1.68619 21.4856L21.4852 1.68664C22.2662 0.905589 23.5326 0.90559 24.3136 1.68664C25.0947 2.46769 25.0947 3.73402 24.3136 4.51507L4.51461 24.3141C3.73356 25.0951 2.46723 25.0951 1.68619 24.3141Z" fill="white"/>
                               <path d="M24.5858 24.2137C25.3668 23.4326 25.3668 22.1663 24.5858 21.3852L4.7868 1.58624C4.00575 0.805186 2.73942 0.805187 1.95837 1.58624C1.17732 2.36728 1.17732 3.63361 1.95837 4.41466L21.7574 24.2137C22.5384 24.9947 23.8047 24.9947 24.5858 24.2137Z" fill="white"/>
                           </svg>
                       )}
                   </div>
               </div>
           </div>
           {cookies && (
               <div className="cookies d-flex justify-content-center fadeIn animated">
                   <div className="cookies__box d-flex align-items-center justify-content-center">
                       <div className="cookies__txt">
                           Данный сайт использует файлы-cookies, а&nbsp;также другие технологии для улучшения работы, совершенствования сервисов, определения пользовательских предпочтений и&nbsp;обеспечения безопасности. Продолжая работу с&nbsp;сайтом, Вы&nbsp;даете согласие на&nbsp;обработку файлов-cookies в&nbsp;соответствии с&nbsp;Политикой конфиденциальности. При несогласии Вы&nbsp;можете отключить обработку файлов-cookies в&nbsp;настройках Вашего браузера или закрыть страницу сайта.
                       </div>
                       <div className="cookies__btn">
                           <div onClick={()=>{
                               localStorage.setItem('cookies', 'yes');
                               setCookies(false);
                           }} className="button d-flex align-items-center justify-content-center">
                               Принять
                           </div>
                       </div>
                   </div>
               </div>
           )}
       </>
    );
}

export default Header;
