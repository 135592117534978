import {useModal} from "../context";
import React, {useEffect} from "react";
import Feedback from "./modals/Feedback";
function Footer() {
    const { show, hide} = useModal();

    return (
        <>
            <div className="footer">
                <div className="vg-decor-7 mb-only"/>
                <div className="container">
                    <div className="footer__nav">
                        <ul>
                            <li>
                                <a onClick={()=>{
                                    show(<Feedback close={hide}/>, "modal--form modal--feedback");
                                }}>Обратная связь</a>
                            </li>
                            <li>
                                <a href="/rules.pdf" target="_blank">Правила акции</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__rights">
                        Период акции, включая период выдачи призов&nbsp;&mdash; с&nbsp;10.07.2024&nbsp;г.&nbsp;по&nbsp;30.08.2024&nbsp;г. Период регистрации на&nbsp;сайте для участия в&nbsp;акции с&nbsp;10.07.2024 по&nbsp;11.08.2024&nbsp;г. Подробности об&nbsp;организаторе акции, правилах и&nbsp;сроках ее&nbsp;проведения, количестве призов, сроках, месте и&nbsp;порядке их&nbsp;получения можно узнать на&nbsp;сайте restoria-leto.ru
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
