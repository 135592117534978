import React, {FunctionComponent, Fragment, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Dialog, Transition } from '@headlessui/react'

export const Modal = ({
    isShown,
    modalContent,
    hide,
    className,
  }) => {

    useEffect(()=>{
        if(isShown){
            setTimeout(function (){
                if(document.getElementById('modal__content')){
                    document.getElementById('modal__content').scroll(0, 0);
                }
            }, 100);
        }
    }, [isShown]);

    return(
        <Transition appear show={isShown} as={Fragment}>
            {/* Modal backdrop */}
            <Dialog as="div" className={'modal ' + className} onClose={()=>{
                if(!className.includes('modal--locked')) {
                    hide()
                }
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div id="modal__backdrop" className="modal__backdrop" />
                </Transition.Child>

                {/* Modal content */}
                <div id="modal__content" className="modal__content">
                    <div className="modal__content-area">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-500"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-200"
                            leave="ease-in duration-150"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="modal__content-box">
                                <div className="modal__content-box-bl">
                                    {!className.includes('modal--locked') && (
                                        <div
                                            id="modal-close"
                                            className="modal__close"
                                            onClick={() => {
                                                hide();
                                            }}
                                        >
                                            <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="20.5" cy="20.5" r="20.5" fill="white" fillOpacity="0.5"/>
                                                <path d="M27 14L14 27" stroke="black" strokeWidth="3" strokeLinecap="round"/>
                                                <path d="M14 14L27 27" stroke="black" strokeWidth="3" strokeLinecap="round"/>
                                            </svg>
                                        </div>
                                    )}

                                    {modalContent}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
};
