import parse from 'html-react-parser';
import {useEffect, useState} from "react";
import {useModal} from "../../context";
import {goToAnchor} from "../../helpers/go-to-anchor";

const shareImage = require("../../assets/img/sharing.jpg");

const cards = [
    {
        1: require("../../assets/img/cards/1.png"),
        2: require("../../assets/img/cards/2.png"),
        3: require("../../assets/img/cards/3.png")
    },
    {
        1: require("../../assets/img/cards/4.png"),
        2: require("../../assets/img/cards/5.png"),
        3: require("../../assets/img/cards/6.png")
    },
    {
        1: require("../../assets/img/cards/7.png"),
        2: require("../../assets/img/cards/8.png"),
        3: require("../../assets/img/cards/9.png")
    }
]

const results = {
    '1-1-1': {
        recipeFile: require("../../assets/recepies/Сэндвичи-с-индейкой.pdf"),
        recipeImg: require("../../assets/img/recipe/1-1-1.jpg"),
        recipeTitle: 'Сэндвичи с&nbsp;индейкой',
        txt: 'Не&nbsp;бойтесь строить амбициозные планы! Двигайтесь к&nbsp;цели постепенно. Подход такой&nbsp;же, как в&nbsp;приготовлении блюд&nbsp;&mdash; по&nbsp;шагам.<br/><br/>Сэндвичи с&nbsp;индейкой готовятся всего 15&nbsp;минут, а&nbsp;заряд энергии они подарят на&nbsp;целый день!'
    },
    '1-1-2': {
        recipeFile: require("../../assets/recepies/Лапша-c-индейкой.pdf"),
        recipeImg: require("../../assets/img/recipe/1-1-2.jpg"),
        recipeTitle: 'Лапша c&nbsp;индейкой',
        txt: 'Карты напоминают: сохраняйте позитивный настрой, какие&nbsp;бы события с&nbsp;вами ни&nbsp;происходили.<br/><br/>От&nbsp;улыбки станет всем светлей, а&nbsp;от&nbsp;лапши из&nbsp;индейки&nbsp;&mdash; обед сытней!'
    },
    '1-1-3': {
        recipeFile: require("../../assets/recepies/Шницель-с-салатом-из-летних-овощей.pdf"),
        recipeImg: require("../../assets/img/recipe/1-1-3.jpg"),
        recipeTitle: 'Шницель с&nbsp;салатом из&nbsp;летних овощей',
        txt: 'После дня, полного горящих дел, не&nbsp;забывайте радовать себя и&nbsp;благодарить за&nbsp;усилия.<br/><br/>Для этого отлично подойдет шницель с&nbsp;жару и&nbsp;легкий салат из&nbsp;летних овощей!'
    },
    '1-2-1': {
        recipeFile: require("../../assets/recepies/Английский-завтрак-с-купатами-Рестория.pdf"),
        recipeImg: require("../../assets/img/recipe/1-2-1.jpg"),
        recipeTitle: 'Английский завтрак с&nbsp;купатами &laquo;Рестория&raquo;',
        txt: 'Подумайте о&nbsp;вечном и&nbsp;сверьтесь с&nbsp;собой. В&nbsp;правильном&nbsp;ли направлении вы&nbsp;двигаетесь?<br/><br/>Загляните в&nbsp;свою душу и&nbsp;в&nbsp;список дел на&nbsp;день. Он&nbsp;должен начинаться с&nbsp;чего-то приятного, например, с&nbsp;английского завтрака от&nbsp;Рестории!'
    },
    '1-2-2': {
        recipeFile: require("../../assets/recepies/Тефтели-из-индейки-и-сливочная-картошка-с-укропом.pdf"),
        recipeImg: require("../../assets/img/recipe/1-2-2.jpg"),
        recipeTitle: 'Нежные тефтели из&nbsp;индейки с&nbsp;картошкой',
        txt: 'Скоро ваша творческая сторона расцветет с&nbsp;новой силой. Не&nbsp;пропустите этот момент и&nbsp;почувствуйте вдохновение.<br/><br/>Такие тефтели вы&nbsp;еще не&nbsp;ели! На&nbsp;даче на&nbsp;обед приготовьте их&nbsp;без бед.'
    },
    '1-2-3': {
        recipeFile: require("../../assets/recepies/Яблочный-тарт.pdf"),
        recipeImg: require("../../assets/img/recipe/1-2-3.jpg"),
        recipeTitle: 'Яблочный тарт',
        txt: 'Карты напоминают, что жизнь должна быть в&nbsp;радость, а&nbsp;блюда&nbsp;&mdash; в&nbsp;сладость!<br/><br/>Не&nbsp;отказывайте себе в&nbsp;удовольствии отдыха и&nbsp;вкусных открытий. Побалуйте себя нежными яблочными тартами из&nbsp;слоёного теста &laquo;Рестория&raquo;.'
    },
    '1-3-1': {
        recipeFile: require("../../assets/recepies/Вафли-с-красной-рыбой-и-майонезным-соусом.pdf"),
        recipeImg: require("../../assets/img/recipe/1-3-1.jpg"),
        recipeTitle: 'Вафли с&nbsp;красной рыбой и&nbsp;майонезным соусом',
        txt: 'Карты советуют настроиться на&nbsp;одну волну со&nbsp;своими желаниями.<br/><br/>Побалуйте себя дарами моря и&nbsp;позавтракайте вафлями с&nbsp;красной рыбой и&nbsp;майонезным соусом.'
    },
    '1-3-2': {
        recipeFile: require("../../assets/recepies/Картофельная-запеканка-с-фаршем-и-сыром.pdf"),
        recipeImg: require("../../assets/img/recipe/1-3-2.jpg"),
        recipeTitle: 'Картофельная запеканка с&nbsp;фаршем и&nbsp;сыром',
        txt: 'Вы&nbsp;славно потрудились. Судьба наградит вас за&nbsp;старания! А&nbsp;вы&nbsp;наградите себя вкусным обедом на&nbsp;работе.<br/><br/>Возьмите с&nbsp;собой картофельную запеканку с&nbsp;фаршем и&nbsp;хрустящей корочкой сыра сверху!'
    },
    '1-3-3': {
        recipeFile: require("../../assets/recepies/Куриные-котлеты-с-пастой-орзо-и-морковью.pdf"),
        recipeImg: require("../../assets/img/recipe/1-3-3.jpg"),
        recipeTitle: 'Куриные котлеты с&nbsp;пастой орзо и&nbsp;морковью',
        txt: 'У&nbsp;вас получится найти баланс и&nbsp;в&nbsp;работе, и&nbsp;в&nbsp;жизни, и&nbsp;в&nbsp;гастрономии.<br/><br/>Попробуйте блюдо, в&nbsp;котором идеально сочетаются белки, сложные углеводы и&nbsp;клетчатка&nbsp;&mdash; куриные котлеты с&nbsp;пастой орзо и&nbsp;морковью.'
    },
    '2-1-1': {
        recipeFile: require("../../assets/recepies/Слоеные-пирожки-с-малиновым-вареньем.pdf"),
        recipeImg: require("../../assets/img/recipe/2-1-1.jpg"),
        recipeTitle: 'Слоёные пирожки с&nbsp;малиновым вареньем',
        txt: 'Самое время проявить свою нежную сторону и&nbsp;сделать жизнь близких слаще!<br/><br/>Когда на&nbsp;даче соберутся ваши дружочки-пирожочки и&nbsp;подружки-ягодки, угостите их&nbsp;слоеными пирожками с&nbsp;малиновым вареньем.'
    },
    '2-1-2': {
        recipeFile: require("../../assets/recepies/Лазанья-из-баклажанов.pdf"),
        recipeImg: require("../../assets/img/recipe/2-1-2.jpg"),
        recipeTitle: 'Лазанья из&nbsp;баклажанов',
        txt: 'Чтобы со&nbsp;вкусом провести время в&nbsp;кругу близких друзей, карты советуют приготовить лазанью!<br/><br/>Она объединяет людей в&nbsp;готовке, а&nbsp;ингредиенты&nbsp;&mdash; в&nbsp;сочное сытное блюдо.'
    },
    '2-1-3': {
        recipeFile: require("../../assets/recepies/Индейка-со-шпинатом-в-остро-сливочном-соусе.pdf"),
        recipeImg: require("../../assets/img/recipe/2-1-3.jpg"),
        recipeTitle: 'Индейка со&nbsp;шпинатом в&nbsp;остро-сливочном соусе',
        txt: 'Карты видят, что вы&nbsp;знаете, как себя подать, но&nbsp;советуют обратить внимание на&nbsp;то, что &laquo;внутри&raquo;.<br/><br/>Возьмите пример с&nbsp;этого блюда. Его можно подавать прямо в&nbsp;сковородке для запекания. Ведь главное&nbsp;&mdash; это оно само и&nbsp;его неповторимый остро-сливочный вкус.'
    },
    '2-2-1': {
        recipeFile: require("../../assets/recepies/Плетеный-пирог-с-вишней.pdf"),
        recipeImg: require("../../assets/img/recipe/2-2-1.jpg"),
        recipeTitle: 'Плетёный пирог с&nbsp;вишней',
        txt: 'Не&nbsp;откладывайте в&nbsp;долгий ящик планы на&nbsp;это жаркое лето и&nbsp;не&nbsp;забывайте про тёплые встречи с&nbsp;близкими! <br/><br/>Достаньте из&nbsp;кухонного шкафа посуду для выпечки. Приготовьте ароматный пирог и&nbsp;разделите его с&nbsp;друзьями за&nbsp;завтраком на&nbsp;даче.'
    },
    '2-2-2': {
        recipeFile: require("../../assets/recepies/Котлеты-по-киевски-с-картофелем-по-деревенски.pdf"),
        recipeImg: require("../../assets/img/recipe/2-2-2.jpg"),
        recipeTitle: 'Котлеты по-киевски с&nbsp;картофелем по-деревенски',
        txt: 'Для вас наступило отличное время, чтобы встретиться с&nbsp;близкими: за&nbsp;жаркими дружескими спорами, зажигающими глаза и&nbsp;огонь в&nbsp;сердце.<br/><br/>Чтобы жаркой была не&nbsp;только встреча, но&nbsp;и&nbsp;блюда на&nbsp;столе, приготовьте котлеты по-киевски с&nbsp;картофелем по-деревенски!'
    },
    '2-2-3': {
        recipeFile: require("../../assets/recepies/Душистый-плов-со-свининой.pdf"),
        recipeImg: require("../../assets/img/recipe/2-2-3.jpg"),
        recipeTitle: 'Душистый плов со&nbsp;свининой',
        txt: 'Карты советуют замедлиться, остановиться и&nbsp;как следует отдохнуть.<br/><br/>Пока плов томится в&nbsp;казане, не&nbsp;томитесь и&nbsp;общайтесь с&nbsp;друзьями. А&nbsp;за&nbsp;ужином даже &laquo;душные&raquo; люди раскроются от&nbsp;душистых специй блюда.'
    },
    '2-3-1': {
        recipeFile: require("../../assets/recepies/Вареники-со-сметанным-соусом-и-сладким-луком.pdf"),
        recipeImg: require("../../assets/img/recipe/2-3-1.jpg"),
        recipeTitle: 'Вареники со&nbsp;сметанным соусом и&nbsp;сладким луком',
        txt: 'Карты видят, что коллеги кормят вас завтраками. Они обещают что-то, а&nbsp;потом забывают.<br/><br/>Угостите их&nbsp;с&nbsp;утра варениками со&nbsp;сметанным соусом и&nbsp;сладким луком. Вкус вас объединит, а&nbsp;дружба поможет найти решения вместе!'
    },
    '2-3-2': {
        recipeFile: require("../../assets/recepies/Запеченная-индейка-в-беконе.pdf"),
        recipeImg: require("../../assets/img/recipe/2-3-2.jpg"),
        recipeTitle: 'Запечённая индейка в&nbsp;беконе',
        txt: 'Карты советуют: обернитесь и&nbsp;посмотрите вокруг! Вас окружают прекрасные люди, готовые помочь в&nbsp;любых делах.<br/><br/>Поблагодарите их&nbsp;за&nbsp;это и&nbsp;предложите коллегам на&nbsp;обед сочную индейку в&nbsp;беконе.'
    },
    '2-3-3': {
        recipeFile: require("../../assets/recepies/Блины-с-мясом-и-перцами.pdf"),
        recipeImg: require("../../assets/img/recipe/2-3-3.jpg"),
        recipeTitle: 'Блины с&nbsp;мясом и&nbsp;перцами',
        txt: 'Те&nbsp;люди вокруг вас, кто подливает масла в&nbsp;огонь, станут спокойнее.<br/><br/>А&nbsp;вы&nbsp;налейте масло на&nbsp;сковороду, пожарьте дома блины, возьмите с&nbsp;собой и&nbsp;угостите коллег в&nbsp;конце продуктивного дня.'
    },
    '3-1-1': {
        recipeFile: require("../../assets/recepies/Вареники-с-вишней-и-взбитыми-сливками.pdf"),
        recipeImg: require("../../assets/img/recipe/3-1-1.jpg"),
        recipeTitle: 'Вареники с&nbsp;вишней и&nbsp;взбитыми сливками',
        txt: 'Карты делают вам вызов: добавьте к&nbsp;завтраку секретный ингредиент&nbsp;&mdash; любовь! Направьте свои кипящие чувства в&nbsp;кулинарию.<br/><br/>Вскипятите воду, сварите вареники с&nbsp;вишней и&nbsp;разделите их&nbsp;с&nbsp;любимкой!'
    },
    '3-1-2': {
        recipeFile: require("../../assets/recepies/Пицца-Ромео-и-Джульетта.pdf"),
        recipeImg: require("../../assets/img/recipe/3-1-2.jpg"),
        recipeTitle: 'Пицца «Ромео и Джульетта»',
        txt: 'В&nbsp;любви вы&nbsp;станете героем романтической пьесы! Порадуйте вторую половинку пиццей &laquo;Ромео и&nbsp;Джульетта&raquo;.<br/><br/>Разделите начинку на&nbsp;две половинки, используйте любимые ингредиенты, а&nbsp;о&nbsp;вкусной основе позаботится &laquo;Рестория&raquo;.'
    },
    '3-1-3': {
        recipeFile: require("../../assets/recepies/Спагетти-с-фаршем.pdf"),
        recipeImg: require("../../assets/img/recipe/3-1-3.jpg"),
        recipeTitle: 'Спагетти с&nbsp;фаршем',
        txt: 'Время показать второй половинке ваши скрытые таланты. Тогда в&nbsp;любви вас будет ждать настоящая поэзия.<br/><br/>Готовьте спагетти, сплетайтесь в&nbsp;дуэте. И&nbsp;главной любимке на&nbsp;свете дарите истории эти!'
    },
    '3-2-1': {
        recipeFile: require("../../assets/recepies/Домашние-гриль-бургеры.pdf"),
        recipeImg: require("../../assets/img/recipe/3-2-1.jpg"),
        recipeTitle: 'Домашние гриль-бургеры',
        txt: 'Ваша забота не&nbsp;останется незамеченной и&nbsp;раскроет новые стороны любви.<br/><br/>Приготовьте своей любимой булочке домашние гриль-бургеры! Зажигайте огонь в&nbsp;мангале и&nbsp;страсть в&nbsp;глазах второй половинки.'
    },
    '3-2-2': {
        recipeFile: require("../../assets/recepies/Шашлык-из-свиной-шейки-с-овощами.pdf"),
        recipeImg: require("../../assets/img/recipe/3-2-2.jpg"),
        recipeTitle: 'Шашлык из&nbsp;свиной шейки с&nbsp;овощами',
        txt: 'Взлетайте на&nbsp;грильях любви со&nbsp;своей любимкой.<br/><br/>Пожарьте шашлык из&nbsp;свиной шейки &laquo;Рестория&raquo;. Сочно будет точно!'
    },
    '3-2-3': {
        recipeFile: require("../../assets/recepies/Домашние-хот-доги.pdf"),
        recipeImg: require("../../assets/img/recipe/3-2-3.jpg"),
        recipeTitle: 'Домашние хот-доги',
        txt: 'Ваша любовь к&nbsp;другим обязательно к&nbsp;вам вернётся.<br/><br/>Делитесь со&nbsp;второй половинкой теплом и&nbsp;разделите за&nbsp;ужином домашние хот-доги с&nbsp;купатами &laquo;Рестория&raquo;!'
    },
    '3-3-1': {
        recipeFile: require("../../assets/recepies/Сырники-с-ванильно-ягодным-соусом.pdf"),
        recipeImg: require("../../assets/img/recipe/3-3-1.jpg"),
        recipeTitle: 'Сырники с&nbsp;ванильно-ягодным соусом',
        txt: 'Дарите любовь, и&nbsp;вы&nbsp;обязательно получите её&nbsp;взамен.<br/><br/>А&nbsp;чтобы начать день на&nbsp;хорошей ноте с&nbsp;коллегой-любимкой, позавтракайте вместе сырниками с&nbsp;ванильно-ягодным соусом. Это будет лучшим подарком с&nbsp;утра!'
    },
    '3-3-2': {
        recipeFile: require("../../assets/recepies/Гуляш-с-овощной-подливкой.pdf"),
        recipeImg: require("../../assets/img/recipe/3-3-2.jpg"),
        recipeTitle: 'Гуляш с&nbsp;овощной подливкой',
        txt: 'Будьте спонтанны и&nbsp;неожиданны в&nbsp;любви! Забирайте любимку из&nbsp;круговорота задач, идите обедать и&nbsp;удивите вихрем вкуса.<br/><br/>В&nbsp;блюде закружатся вместе нежность овощей, терпкость специй и&nbsp;сочность свиного гуляша &laquo;Рестория&raquo;!'
    },
    '3-3-3': {
        recipeFile: require("../../assets/recepies/Пирог-с-сыром-и-ветчиной.pdf"),
        recipeImg: require("../../assets/img/recipe/3-3-3.jpg"),
        recipeTitle: 'Пирог с&nbsp;сыром и&nbsp;ветчиной',
        txt: 'В&nbsp;конце рабочего дня сворачивайте не&nbsp;только дела, но&nbsp;и&nbsp;тесто.<br/><br/>Освободите время для ужина с&nbsp;коллегой-любимкой и&nbsp;место в&nbsp;желудке для вкуснейшего пирога с&nbsp;сыром и&nbsp;ветчиной!'
    }
}

function Result({result = [1, 1, 1], resetPrediction, close}){
    const [resultStr, setResultStr] = useState('');
    const { show, hide} = useModal();

    useEffect(()=>{
        setResultStr(result.join('-'));
    }, [result]);

    return (
        <div className="modal__ct">
            <div onClick={()=>{
                hide();
            }} className="btn__back d-flex align-items-center justify-content-center mb-only">
                <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 2L2 11L11 20" stroke="black" strokeWidth="2.5" strokeLinecap="round"/>
                </svg>
            </div>
            <div className="modal__ct-box">
                <div className="modal__title text-center">
                    Результат
                </div>
            </div>
            <div className="modal__ct-box">
                <div className="result__cards">
                    <div className="result__card">
                        <img src={cards[0][result[0]]}/>
                    </div>
                    <div className="result__card">
                        <img src={cards[1][result[1]]}/>
                    </div>
                    <div className="result__card">
                        <img src={cards[2][result[2]]}/>
                    </div>
                </div>
            </div>
            {resultStr && (
                <>
                    <div className="modal__ct-box">
                        <div className="modal__text text-left">
                            {parse(results[resultStr].txt)}
                        </div>
                    </div>
                    <div className="modal__ct-box">
                        <div className="result__btns d-flex justify-content-center">
                            <button onClick={()=>{
                                resetPrediction();
                                goToAnchor('prediction');
                                close();
                            }} type="button" className="button button--medium button--white d-flex align-items-center justify-content-center">
                                Попробовать еще
                            </button>
                        </div>
                    </div>
                    <div className="modal__ct-box">
                        <div className="recipe__header mb-only">
                            <div className="recipe__box-icon">
                                <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="30" cy="30.2041" r="30" fill="#F4EDE6"/>
                                    <path d="M39.5493 21.8825C39.0781 16.7649 35.3597 13.4041 30.0001 13.4041C24.6405 13.4041 20.9221 16.7649 20.4509 21.8825C16.3605 22.4445 13.2001 25.9621 13.2001 30.2041C13.2001 33.9705 15.6693 37.2061 19.2001 38.2489V45.8041C19.2001 46.4669 19.7373 47.0041 20.4001 47.0041H39.6001C40.2629 47.0041 40.8001 46.4669 40.8001 45.8041V38.2489C44.3309 37.2061 46.8001 33.9705 46.8001 30.2041C46.8001 25.9621 43.6397 22.4445 39.5493 21.8825ZM21.6001 44.6041V42.2041H38.4001V44.6041H21.6001ZM39.4009 36.1125C38.8233 36.2101 38.4001 36.7105 38.4001 37.2961V39.8041H21.6001V37.2961C21.6001 36.7101 21.1769 36.2097 20.5993 36.1125C17.7025 35.6257 15.6001 33.1409 15.6001 30.2041C15.6001 26.8957 18.2917 24.2041 21.6001 24.2041C22.2629 24.2041 22.8001 23.6669 22.8001 23.0041C22.8001 18.6301 25.6261 15.8041 30.0001 15.8041C34.3741 15.8041 37.2001 18.6301 37.2001 23.0041C37.2001 23.6669 37.7373 24.2041 38.4001 24.2041C41.7085 24.2041 44.4001 26.8957 44.4001 30.2041C44.4001 33.1409 42.2977 35.6257 39.4009 36.1125Z" fill="black"/>
                                    <path d="M37.1999 26.6041V32.6041C37.1999 33.2669 36.6627 33.8041 35.9999 33.8041C35.3371 33.8041 34.7999 33.2669 34.7999 32.6041V26.6041C34.7999 25.9413 35.3371 25.4041 35.9999 25.4041C36.6627 25.4041 37.1999 25.9413 37.1999 26.6041Z" fill="black"/>
                                    <path d="M31.1999 26.6041V32.6041C31.1999 33.2669 30.6627 33.8041 29.9999 33.8041C29.3371 33.8041 28.7999 33.2669 28.7999 32.6041V26.6041C28.7999 25.9413 29.3371 25.4041 29.9999 25.4041C30.6627 25.4041 31.1999 25.9413 31.1999 26.6041Z" fill="black"/>
                                    <path d="M25.1999 26.6041V32.6041C25.1999 33.2669 24.6627 33.8041 23.9999 33.8041C23.3371 33.8041 22.7999 33.2669 22.7999 32.6041V26.6041C22.7999 25.9413 23.3371 25.4041 23.9999 25.4041C24.6627 25.4041 25.1999 25.9413 25.1999 26.6041Z" fill="black"/>
                                </svg>
                            </div>
                            <div className="recipe__box-title text-left">
                                {parse(results[resultStr].recipeTitle)}
                            </div>
                        </div>
                        <div className="recipe__box d-flex">
                            <div className="recipe__box-img">
                                <img src={results[resultStr].recipeImg}/>
                            </div>
                            <div className="recipe__box-ct">
                                <div className="recipe__box-icon desk-only">
                                    <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="30" cy="30.2041" r="30" fill="#F4EDE6"/>
                                        <path d="M39.5493 21.8825C39.0781 16.7649 35.3597 13.4041 30.0001 13.4041C24.6405 13.4041 20.9221 16.7649 20.4509 21.8825C16.3605 22.4445 13.2001 25.9621 13.2001 30.2041C13.2001 33.9705 15.6693 37.2061 19.2001 38.2489V45.8041C19.2001 46.4669 19.7373 47.0041 20.4001 47.0041H39.6001C40.2629 47.0041 40.8001 46.4669 40.8001 45.8041V38.2489C44.3309 37.2061 46.8001 33.9705 46.8001 30.2041C46.8001 25.9621 43.6397 22.4445 39.5493 21.8825ZM21.6001 44.6041V42.2041H38.4001V44.6041H21.6001ZM39.4009 36.1125C38.8233 36.2101 38.4001 36.7105 38.4001 37.2961V39.8041H21.6001V37.2961C21.6001 36.7101 21.1769 36.2097 20.5993 36.1125C17.7025 35.6257 15.6001 33.1409 15.6001 30.2041C15.6001 26.8957 18.2917 24.2041 21.6001 24.2041C22.2629 24.2041 22.8001 23.6669 22.8001 23.0041C22.8001 18.6301 25.6261 15.8041 30.0001 15.8041C34.3741 15.8041 37.2001 18.6301 37.2001 23.0041C37.2001 23.6669 37.7373 24.2041 38.4001 24.2041C41.7085 24.2041 44.4001 26.8957 44.4001 30.2041C44.4001 33.1409 42.2977 35.6257 39.4009 36.1125Z" fill="black"/>
                                        <path d="M37.1999 26.6041V32.6041C37.1999 33.2669 36.6627 33.8041 35.9999 33.8041C35.3371 33.8041 34.7999 33.2669 34.7999 32.6041V26.6041C34.7999 25.9413 35.3371 25.4041 35.9999 25.4041C36.6627 25.4041 37.1999 25.9413 37.1999 26.6041Z" fill="black"/>
                                        <path d="M31.1999 26.6041V32.6041C31.1999 33.2669 30.6627 33.8041 29.9999 33.8041C29.3371 33.8041 28.7999 33.2669 28.7999 32.6041V26.6041C28.7999 25.9413 29.3371 25.4041 29.9999 25.4041C30.6627 25.4041 31.1999 25.9413 31.1999 26.6041Z" fill="black"/>
                                        <path d="M25.1999 26.6041V32.6041C25.1999 33.2669 24.6627 33.8041 23.9999 33.8041C23.3371 33.8041 22.7999 33.2669 22.7999 32.6041V26.6041C22.7999 25.9413 23.3371 25.4041 23.9999 25.4041C24.6627 25.4041 25.1999 25.9413 25.1999 26.6041Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className="recipe__box-title text-left desk-only">
                                    {parse(results[resultStr].recipeTitle)}
                                </div>
                                <div className="recipe__box-action d-flex">
                                    <a href={results[resultStr].recipeFile} target="_blank" download className="btn__download d-flex align-items-center">
                                        <span>Скачать рецепт</span>
                                        <i className="icon__pdf">
                                            pdf
                                        </i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal__ct-box">
                        <div className="modal__text text-center">
                            Поделиться
                        </div>
                        <div className="share__links d-flex justify-content-center">
                            <div onClick={()=>{
                                window.open('http://vk.com/share.php?url=' + window.location.href + '&image=' + shareImage,'','toolbar=0,status=0,width=626,height=436');
                            }} className="share__link">
                                <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="30" cy="30.2041" r="30" fill="black"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M24.9 29.2438C23.5403 27.7266 22.4268 26.0058 21.6 24.1438C21.3 23.5438 20.85 23.2438 20.1 23.2438H15.45C15 23.3938 15 23.6938 15 24.1438L16.5 26.8438C17.7607 29.1978 19.2693 31.4103 21 33.4438C22.2 34.6438 23.55 35.6938 25.2 36.4438C27.15 37.4938 29.25 37.6438 31.35 37.4938C32.1 37.4938 32.55 37.1938 32.55 36.5938L33 34.6438C33.3 34.0438 33.9 34.0438 34.5 34.3438L35.1 34.9438L35.85 35.5438L37.2 36.7438C37.8 37.3438 38.7 37.6438 39.6 37.6438H43.8C45 37.4938 45.3 36.7438 44.7 35.8438C44.2531 35.2015 43.7512 34.5992 43.2 34.0438L41.7 32.6938C41.2956 32.3997 40.8956 32.0997 40.5 31.7938C40.05 31.3438 40.05 31.0438 40.35 30.4438L40.65 29.9938L41.7 28.7938L43.5 26.5438C44.1 25.7938 44.55 25.0438 44.85 24.1438C44.85 23.8438 44.85 23.5438 44.4 23.3938H39C38.1 23.3938 37.8 23.3938 37.5 24.1438C36.5632 26.1801 35.2385 28.0143 33.6 29.5438C33.15 29.9938 32.7 29.8438 32.55 29.2438V27.8938C32.5132 26.4941 32.5132 25.0936 32.55 23.6938C32.4 23.2438 32.1 22.9438 31.5 22.7938H26.85C26.1 22.6438 25.5 22.6438 25.05 23.0938C24.75 23.3938 24.9 23.6938 25.2 23.6938C26.25 23.8438 26.7 24.4438 26.7 25.1938V27.8938L26.4 29.3938C26.25 29.6938 25.95 29.8438 25.5 29.5438C25.282 29.4195 25.0804 29.2683 24.9 29.0938V29.2438Z" fill="white"/>
                                </svg>
                            </div>
                            <div onClick={()=>{
                                window.open('https://t.me/share?url=' + window.location.href + '&text=' + document.querySelector('meta[name="description"]').content);
                            }} className="share__link">
                                <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="30" cy="30.2041" r="30" fill="black"/>
                                    <path d="M38.7914 19.4947C37.0664 20.1547 16.1714 28.6747 15.8564 28.8247C14.8364 29.3347 14.7164 30.0247 15.5564 30.4747C15.6464 30.5197 17.0564 30.9997 18.7064 31.5397L21.7064 32.5297L28.5164 28.0297C30.827 26.4828 33.1521 24.9578 35.4914 23.4547C35.7164 23.3647 35.9264 23.3647 36.0164 23.4697C36.0764 23.5447 36.0764 23.5747 36.0164 23.6797C36.0014 23.7397 33.5114 26.1397 30.5114 28.9897L25.0214 34.2247L24.7964 37.3747L24.6014 40.5247L24.8264 40.4947C24.9834 40.4689 25.1353 40.4183 25.2764 40.3447C25.3814 40.2847 26.1764 39.5347 27.0014 38.6947C27.8414 37.8247 28.5614 37.1197 28.6064 37.1047C28.6364 37.1047 29.9714 38.0947 31.5764 39.3397C33.1664 40.5697 34.6064 41.6497 34.7564 41.7397C35.1614 41.9197 35.6114 41.9497 35.8964 41.7697C36.1664 41.6197 36.4214 41.2597 36.5264 40.8697L38.5514 30.9247C40.3364 22.0747 40.5014 21.2047 40.5014 20.7247C40.5014 20.2897 40.4864 20.1997 40.3814 19.9747C40.3015 19.805 40.1834 19.6561 40.0364 19.5397C39.7364 19.3297 39.2564 19.2997 38.7764 19.4797L38.7914 19.4947Z" fill="white"/>
                                </svg>
                            </div>
                            <div onClick={()=>{
                                window.open('https://wa.me/?text=%D0%94%D0%B0%D0%B2%D0%B0%D0%B9%20%D0%BF%D1%80%D0%B8%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%B8%D0%BC%20%D0%B2%D0%BC%D0%B5%D1%81%D1%82%D0%B5%3F%20%D0%AD%D1%82%D0%B8%D0%BC%20%D0%BB%D0%B5%D1%82%D0%BE%D0%BC%20%D0%BC%D1%8B%20%D1%83%D1%81%D0%BF%D0%B5%D0%B5%D0%BC%20%D0%B2%D1%81%D1%91%20%D0%B2%D0%BC%D0%B5%D1%81%D1%82%D0%B5%20%D1%81%20%D0%A0%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B8%D0%B5%D0%B9%2C%20%D0%B8%20%D0%B5%D1%89%D1%91%20%D0%BC%D0%BE%D0%B6%D0%B5%D0%BC%20%D0%B2%D1%8B%D0%B8%D0%B3%D1%80%D0%B0%D1%82%D1%8C%20200%20000%20%D1%80%D1%83%D0%B1%D0%BB%D0%B5%D0%B9.%20%D0%9F%D1%80%D0%BE%D1%81%D1%82%D0%BE%20%D0%B7%D0%B0%D1%85%D0%BE%D0%B4%D0%B8%20%D0%BD%D0%B0%20restoria-leto.ru%20%E2%98%80%EF%B8%8F%20' + window.location.href);
                            }} className="share__link">
                                <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="30" cy="30.2041" r="30" fill="black"/>
                                    <path d="M21.0976 24.3392C21.2685 22.9876 23.0249 21.1508 24.4189 21.3546L24.4167 21.3524C25.7733 21.6102 26.8471 23.9451 27.4527 24.995C27.8817 25.7567 27.6031 26.5285 27.2029 26.8542C26.6631 27.2893 25.8188 27.8897 25.996 28.4925C26.3109 29.5642 30.0496 33.3029 31.8884 34.3658C32.5844 34.7681 33.0892 33.707 33.5202 33.1634C33.8333 32.7453 34.6058 32.4954 35.3658 32.9081C36.5008 33.5691 37.5701 34.3374 38.5589 35.2022C39.0527 35.615 39.1686 36.225 38.8265 36.8697C38.2238 38.0052 36.4806 39.5026 35.2153 39.1983C33.0053 38.6669 24.0676 35.2022 21.1968 25.1645C21.0353 24.6896 21.076 24.5101 21.0976 24.3392Z" fill="white"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M30.0496 46.7623C28.2197 46.7623 27.2073 46.566 25.5632 46.0146L22.4143 47.589C20.4256 48.5834 18.0857 47.1372 18.0857 44.9138V41.5281C14.8652 38.5252 13.5992 35.0627 13.5992 30.3119C13.5992 21.2267 20.9643 13.8616 30.0496 13.8616C39.1349 13.8616 46.5 21.2267 46.5 30.3119C46.5 39.3972 39.1349 46.7623 30.0496 46.7623ZM21.0767 40.2275L20.1255 39.3405C17.6234 37.0076 16.5902 34.3993 16.5902 30.3119C16.5902 22.8785 22.6162 16.8525 30.0496 16.8525C37.4831 16.8525 43.509 22.8785 43.509 30.3119C43.509 37.7454 37.4831 43.7713 30.0496 43.7713C28.5755 43.7713 27.8842 43.6382 26.5142 43.1788L25.3365 42.7839L21.0767 44.9138V40.2275Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Result;
