import { Routes, Route } from 'react-router-dom'
import React from 'react';
import {ModalProvider} from "./context";
import Home from './pages/Home'
import {Helmet} from "react-helmet";

function App() {
  return (
      <>
          <Helmet>
              <title></title>
              <meta property="og:title" content=""/>
          </Helmet>
          <ModalProvider>
              <div id="wrapper" className="wrapper">
                  <Routes>
                      <Route path="/" element={<Home />} />
                  </Routes>
              </div>
          </ModalProvider>
      </>
  );
}

export default App;
