import parse from 'html-react-parser';
import {Link, useLocation} from 'react-router-dom'

function Info({title = '', feedback = false, bad= false, text = '', hint = '', icon = false, regBtn = false, close}){
    const location = useLocation();

    return (
        <>
            <div className="modal__ct">
                {title && (
                    <div className="modal__ct-box">
                        <div className="modal__title">
                            {parse(title)}
                        </div>
                    </div>
                )}
                {text && (
                    <div className="modal__ct-box">
                        <div className="modal__text text-center">
                            {parse(text)}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Info;
