import React, {useState, useEffect} from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal} from "../../context";
import Info from "./Info";

const topics = [
    {
        title: 'Регистрация в акции',
        id: 1
    },
    {
        title: 'Получение призов',
        id: 2
    },
    {
        title: 'Технические вопросы',
        id: 3
    },
    {
        title: 'Другое',
        id: 4
    }
];

function Feedback({product = false}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [theme, setTheme] = useState('');
    const [themeTitle, setThemeTitle] = useState('');
    const [attachFile, setAttachFile] = useState('');
    const [isThemeDropdownOpen, setIsThemeDropdownOpen] = useState(false);

    const { show, hide} = useModal();

    const schema = yup.object({
        name: yup.string().required('Некорректное Имя'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        message: yup.string().min(6, 'Мин. 5 символов').required('Обязательно к заполнению'),
        theme: yup.string().required('Выберите тему обращения'),
        agree: yup.boolean().oneOf([true], 'Необходимо согласие'),
    }).required();

    const { register,reset, setValue, clearErrors, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchEmail = watch("email");
    const watchName = watch("name");
    const watchMess = watch("message");
    const watchTheme = watch("theme");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        const fileInput = document.querySelector('#file') ;
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("text", data.message);
        formData.append("subject_id", data.theme);
        formData.append("accept1", 1);

        if(fileInput.files[0] !== undefined){
            formData.append('file', fileInput.files[0]);
        }

        let headers = {
            Authorization: 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
        }

        await fetch(process.env.REACT_APP_API_PROXY + 'sendFeedback', {
            method: "POST",
            body: formData,
            headers: headers
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                reset();
                setAttachFile('');
                setThemeTitle('');
                setTheme('');
                show(<Info
                    icon={true}
                    title="Спасибо <br/> за&nbsp;обращение!"
                    text="Ваш вопрос будет обработан в&nbsp;течение 72&nbsp;часов. <br/>Ответ будет отправлен на&nbsp;указанный электронный адрес"
                    close={hide}/>, "modal--info");
            }else{
                if(response.error.email){
                    setError('email', { type: 'custom', message: response.error.email[0] });
                }

                if(response.error.name){
                    setError('name', { type: 'custom', message: response.error.name[0] });
                }

                if(response.error.text){
                    setError('message', { type: 'custom', message: response.error.text[0] });
                }
            }

            setIsSubmitting(false);
        });
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__title">
                        Обратная связь
                    </div>
                </div>

                <div className="modal__ct-box">
                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.name ? ' has-error' : '') + ((watchName && watchName != '') ? ' has-value' : '')}>
                                        <input
                                            type="text"
                                            id="name"
                                            placeholder="ФИО"
                                            {...register("name")}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p className="error__message">
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.email ? ' has-error' : '') + ((watchEmail && watchEmail != '') ? ' has-value' : '')}>
                                        <input
                                            type="text"
                                            id="email"
                                            placeholder="E-mail"
                                            {...register("email")}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p className="error__message">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__select' + (isThemeDropdownOpen ? ' active' : '') + (theme != '' ? ' has-value' : '') + (errors.theme ? ' has-error' : '')}>
                                        <div onClick={()=>{
                                            setIsThemeDropdownOpen(!isThemeDropdownOpen);
                                        }} className="form__select-input d-flex align-items-center justify-content-between">
                                            {!theme && (
                                                <div className="form__label">
                                                    Тема обращения
                                                </div>
                                            )}
                                            {theme && (
                                                <div className="form__select-value">
                                                    {themeTitle}
                                                </div>
                                            )}
                                            <div className="form__select-toggle">
                                                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13 1L7 7L1 0.999999" stroke="black" strokeWidth="2"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="form__select-dropdown">
                                            <div className="form__dropdown-list">
                                                {topics.map((it, i) => (
                                                    <div onClick={()=>{
                                                        setIsThemeDropdownOpen(false);
                                                        setThemeTitle(it.title);
                                                        setTheme(it.id);
                                                        setValue('theme', it.id);
                                                        clearErrors('theme');
                                                    }} className={'form__dropdown-it' + (it.id == theme ? ' active' : '')}>
                                                        {it.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {errors.theme && (
                                        <p className="error__message">
                                            {errors.theme?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field form__field--textarea' + (errors.message ? ' has-error' : '') + ((watchMess && watchMess != '') ? ' has-value' : '')}>
                                        <textarea
                                            id="message"
                                            {...register("message")}
                                            placeholder="Текст обращения"
                                        />
                                    </div>
                                    {errors.message && (
                                        <p className="error__message">
                                            {errors.message?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--attach">
                                    <label className={'d-flex align-items-center justify-content-center form__attach-btn' + (attachFile ? ' hidden' : '')}>
                                        <input type="file" id="file" {...register("attachment")} accept="image/*" onChange={(event)=>{
                                            const files = event.target.files;
                                            const fileName = files[0].name;
                                            setAttachFile(fileName);
                                        }}/>
                                        <div className={'file__attach-btn-icon d-flex align-items-center justify-content-center' + (attachFile != '' ? ' has-image' : '')}>
                                            <i className="icon-file">
                                                <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.72747 25.997C6.4887 25.997 4.25145 25.1443 2.54768 23.4405C-0.858342 20.033 -0.858342 14.487 2.54768 11.081L13.2947 0.333991C13.74 -0.11133 14.4619 -0.11133 14.9072 0.333991C15.3526 0.779312 15.3526 1.50125 14.9072 1.94657L4.16026 12.6936C1.64336 15.212 1.64336 19.3111 4.16026 21.8295C6.67868 24.3449 10.7778 24.3479 13.2962 21.8295L23.5067 11.619C25.1344 9.98971 25.1344 7.33754 23.5067 5.70824C21.8774 4.07895 19.2252 4.08047 17.5944 5.70824L7.38542 15.9172C7.02673 16.2759 6.82915 16.7531 6.82915 17.2608C6.82915 17.7684 7.02673 18.2441 7.38542 18.6043C8.10432 19.3232 9.35365 19.3232 10.0725 18.6043L17.5944 11.0825C18.0397 10.6372 18.7616 10.6372 19.207 11.0825C19.6523 11.5278 19.6523 12.2498 19.207 12.6951L11.6851 20.2169C10.1045 21.796 7.34895 21.796 5.77436 20.2169C4.98403 19.4281 4.54935 18.3794 4.54935 17.2623C4.54935 16.1452 4.98403 15.0965 5.77436 14.3077L15.9833 4.0987C18.4987 1.5818 22.6008 1.57876 25.1192 4.0987C27.6361 6.61713 27.6361 10.7147 25.1192 13.2331L14.9088 23.4436C13.205 25.1474 10.9678 25.9985 8.73051 26L8.72747 25.997Z" fill="black"/>
                                                </svg>
                                            </i>
                                        </div>
                                        <span>{attachFile ? attachFile : 'Прикрепить изображение'}</span>
                                    </label>
                                    {attachFile && (
                                        <div className="attached__file d-flex align-items-center justify-content-between">
                                            <span className="attach-name">{attachFile}</span>
                                            <div onClick={()=>{
                                                setValue('attachment', null);
                                                setAttachFile('');
                                            }} className="file__clear">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 16L16 2" stroke="#D02E26" strokeWidth="3"/>
                                                    <path d="M16 16L2 2" stroke="#D02E26" strokeWidth="3"/>
                                                </svg>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className="checkbox__it">
                                        <div className={'checkbox__wrap' + (errors.agree ? ' has-error' : '')}>
                                            <label className="align-items-start">
                                                <input
                                                    type="checkbox"
                                                    {...register("agree")}
                                                />
                                                <div className="checkbox__decor">
                                                    <div className="checkbox__decor-bg"/>
                                                </div>
                                                <div className="checkbox__title text-left">
                                                    Я&nbsp;даю согласие на&nbsp;обработку <a href="/user-agreement.pdf" target="_blank">персональных данных</a>
                                                </div>
                                            </label>
                                        </div>
                                        {errors.agree && (
                                            <p className="error__message">
                                                {errors.agree?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 form__it">
                                    <button type="submit" className="button button--medium w-100 d-flex align-items-center justify-content-center">
                                        Задать вопрос
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Feedback;
